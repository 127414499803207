<template>
  <div>
    <a-affix :offset-top="0">
      <a-page-header class="header-affix mb-4">
        <a-form>
          <div class="row">
            <div class="col-12 mb-4">
              <div class="row">
<!--                <div class="col-md-2">-->
<!--                  <a-input v-model:value="searchName" placeholder="Поиск по имени" @change="searchByName" allow-clear />-->
<!--                </div>-->
<!--                <div class="col-md-2">-->
<!--                  <a-input v-model:value="searchPhone" placeholder="Поиск по номеру телефона" @change="searchByPhone" allow-clear />-->
<!--                </div>-->
<!--                <div class="col-md-2">-->
<!--                  <a-input v-model:value="searchEmail" placeholder="Поиск по почте" @change="searchByEmail" allow-clear />-->
<!--                </div>-->
                <div class="col-md-2">
                  <a-input v-model:value="search" placeholder="Поиск по имени" @change="find" allow-clear />
                </div>
                <div class="col-md-2">
                  <a-select
                    v-if="user.role === 'admin'"
                    v-model:value="brand"
                    :options="brands"
                    @change="searchByBrand"
                    style="width: 100%"
                    :option-filter-prop="'label'"
                    show-search
                  />
                </div>
                <div class="col-md-2">
                  <a-select
                    v-if="user.role === 'admin'"
                    v-model:value="store"
                    :options="stores"
                    @change="searchByStore"
                    style="width: 100%"
                    :option-filter-prop="'label'"
                    show-search
                  />
                </div>
                <div class="col-md-6 text-right">
                  <a-button class="w-100 text-right" @click="clearFilters" shape="round" type="link">
                    <template #icon><CloseCircleOutlined /></template>
                    Сбросить фильтр
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </a-page-header>
    </a-affix>
    <a-button
      v-if="user.role === 'admin'"
      type="link"
      style="color: #ff4d4f; float: right"
      @click="router.push({ name: 'users-archived' })"
    >
      <template #icon><DeleteOutlined /></template>
      <span>Архив пользователей</span>
    </a-button>
    <vb-create-user :roles="rolesList" @reload="reload" />
    <vb-edit-user
      v-if="editModalVisible"
      :user-id="userId"
      :roles="rolesList"
      @reload="reload"
      @hide-modal="hideEditModal" />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      row-key="id"
    >
      <template #phone="{ text }">
        {{ formattedPhone(text) }}
      </template>
      <template #role="{ record }">
        {{ roles[record.role] }}
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="top">
            <template #title>Обновить пароль</template>
            <a @click="resetPassword(record.id)" class="mx-1">
              <LoadingOutlined v-if="resetPasswordLoading === record.id" />
              <i v-else class="fe fe-lock"></i>
            </a>
          </a-tooltip>
          <a-tooltip placement="topRight">
            <template #title>Редактировать пользователя</template>
            <a @click="showEditModal(record.id)" class="mx-1"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="topRight">
            <template #title>Удалить пользователя</template>
            <a @click="showDeleteModal(record.id)" class="mx-1"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal
      v-model:visible="successModal"
      :width="320"
      :closable="false"
      cancel-text="Закрыть"
      @ok="successModal = false"
      title="Пароль обновлен"
    >
      <check-circle-outlined :style="{fontSize: '18px', lineHeight: '28px', display: 'block', float: 'left', color: '#52c41a', marginRight: '10px'}" />
      <span>Новый пароль выслан по email</span>
    </a-modal>
    <a-modal
      v-model:visible="errorModal"
      :width="320"
      :closable="false"
      cancel-text="Закрыть"
      @ok="errorModal = false"
      title="Ошибка"
    >
      <close-circle-outlined :style="{fontSize: '18px', lineHeight: '28px', display: 'block', float: 'left', color: '#eb2f96', marginRight: '10px'}" />
      <span>Не удалось отправить сообщение</span>
    </a-modal>
    <a-modal
      v-model:visible="deleteModalVisible"
      :confirm-loading="deleteLoading"
      @cancel="hideDeleteModal"
      :footer="null"
    >
      <a-typography-title :level="5">Вы уверены что хотите удалить пользователя?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteUser" type="primary" class="float-right">Удалить</a-button>
        <a-button @click="hideDeleteModal" type="secondary" class="float-right mx-2">Отменить</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { mask } from "maska";
import {useStore} from "vuex";
import apiClient from "@/services/axios";
import {notification} from "ant-design-vue";
import VbEditUser from "@/views/users/edit";
import {useRoute, useRouter} from "vue-router";
import VbCreateUser from "@/views/users/create";
import {computed, onMounted, reactive, ref, toRefs, watch} from "vue";
import {CloseCircleOutlined, CheckCircleOutlined, LoadingOutlined, DeleteOutlined} from "@ant-design/icons-vue";

let
  timer = null,
  store = ref(''),
  stores = ref([]),
  brand = ref(''),
  brands = ref([]),
  search = ref(''),
  searchName = ref(''),
  searchPhone = ref(''),
  searchEmail = ref(''),
  errorModal = ref(false),
  successModal = ref(false),
  resetPasswordLoading = ref(0),
  loading = ref(false),
  editModalVisible = ref(false),
  deleteModalVisible = ref(false),
  deleteLoading = ref(false),
  dataSource = ref([]),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
    phone: '',
    search: '',
    store_id: '',
    brand_id: ''
  },
  queryParams = reactive({...initialParams}),
  roles = {
    admin: 'Администратор',
    manager: 'Менеджер',
    brandmanager: 'Бренд менеджер',
    engineer: 'Инженер',
    cashier: 'Кассир',
    guest: 'Гость',
    support: 'Суппорт',
    marketer: 'Маркетолог'
  },
  total = ref(0),
  userId = ref(0),
  rolesList = ref([]);

const
  user = computed(() => useStore().getters['user/user']),
  columns = [
    {
      title: 'Имя пользователя',
      sorter: true,
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email адрес',
      dataIndex: 'email',
      sorter: true,
      key: 'email',
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phone',
      key: 'phone',
      slots: { customRender: 'phone' },
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      slots: { customRender: 'role' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  find = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ search: e.target.value })
    }, 800);
  },
  searchByName = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ username: e.target.value })
    }, 800);
  },
  // searchByPhone = (e) => {
  //   if (e.target.value.length > 0 && e.target.value.length < 3)
  //     return;
  //   if (timer) {
  //     clearTimeout(timer);
  //     timer = null;
  //   }
  //   timer = setTimeout(() => {
  //     updateParams({ phone: e.target.value })
  //   }, 800);
  // },
  // searchByEmail = (e) => {
  //   if (e.target.value.length > 0 && e.target.value.length < 3)
  //     return;
  //   if (timer) {
  //     clearTimeout(timer);
  //     timer = null;
  //   }
  //   timer = setTimeout(() => {
  //     updateParams({ email: e.target.value })
  //   }, 800);
  // },
  searchByStore = (id) => {
    updateParams({ store_id: id })
  },
  searchByBrand = (id) => {
    updateParams({ brand_id: id })
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({sort: sign + sorter.field})
    }
  },
  showEditModal = (id) => {
    userId.value = id
    editModalVisible.value = true;
  },
  showDeleteModal = (id) => {
    userId.value = id
    deleteModalVisible.value = true;
  },
  deleteUser = () => {
    deleteLoading.value = true
    apiClient.delete(`admin/delete?id=${userId.value}`)
      .then(res => {
        if (res)
          notification.success({
            message: 'Пользователь удален',
          })
      }).catch(() => {
        notification.error({
          message: 'Возникла ошибка при удалении сотрудника',
        })
    }).finally(() => {
      deleteLoading.value = false
      hideDeleteModal()
      reload()
    })
  },
  resetPassword = (id) => {
    if (!confirm("Вы уверены?"))
      return;
    resetPasswordLoading.value = id
    apiClient.get(`admin/reset?id=${id}`)
      .then(res => {
        if (res)
          successModal.value = true
          // Modal.success({
          //   title: 'Пароль обновлен',
          //   content: `Новый пароль выслан по email`,
          // });
      }).catch(err => {
        errorModal.value = true
          // Modal.error({
          //   title: 'Ошибка',
          //   content: `Не удалось отправить сообщение`,
          // });
      }).finally(() => {
        resetPasswordLoading.value = 0
      })
  },
  route = useRoute(),
  router = useRouter(),
  hideEditModal = () => {
    setTimeout(() => {
      editModalVisible.value = false
    }, 100)
  },
  hideDeleteModal = () => {
    setTimeout(() => {
      deleteModalVisible.value = false
    }, 100)
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  clearFilters = () => {
    Object.assign(queryParams, initialParams);

    search.value = ''
    searchName.value = ''
    searchPhone.value = ''
    searchEmail.value = ''
    store.value = ''
    brand.value = ''

    reload()
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get(`/admin`, { params }).then(({ data }) => {
      dataSource.value = data.data.items
      rolesList.value = data.data.roles
      loading.value = false
      stores.value = data.data.stores
      brands.value = data.data.brands
      total.value = data.data.total
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  getData({
    ...queryParams,
  })
})
watch(queryParams, () => {
  router.push({
    name: 'users',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
});

const query = {...toRefs(queryParams)}

</script>

<style>
[data-vb-theme="default"] .ant-table-thead > tr > th{
  background: none;
}
.header-affix{
  border: 1px solid #e4e9f0;
  background-color: #ffffff !important;
  box-shadow: 0 4px 10px 0 #14130b0d, 0 0 10px 0 #1413110f;
  border-radius: 0.25rem;
}
</style>
